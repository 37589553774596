import { getUserTokenLocalStorage, logoutUser, redirectToLogin } from './auth'


export const login = credentials => apiFetch('/jwt/v1/auth/', credentials)

export const requestPasswordRecovery = credentials => apiFetch('/users/request-password-recovery/', credentials)

export const setNewPassword = credentials => apiFetch('/users/set-new-password/', credentials)

export const checkPasswordResetKey = credentials => apiFetch('/users/check-password-reset-key/', credentials)


export const getCompany = () => apiFetch('/users/account/')

export const getCompanies = () => apiFetch('/users/companies/')

export const switchCompany = companyId => apiFetch('/users/switch-company/', { companyId })

export const getNotifications = () => apiFetch('/companies/updates/')


export const getProducts = () => apiFetch('/products/inventory/')

export const getProductTerms = () => apiFetch('/products/terms/')


export const getUnits = () => apiFetch('/company/units/')

export const getUnit = id => apiFetch(`/company/units/${id}`)

export const updateUnit = (id, payload) => apiFetch(`/company/units/${id}`, payload)

export const insertUnit = payload => apiFetch('/company/units/', payload)

export const getUser = () => apiFetch('/users/company/')

export const updateUser = payload => apiFetch('/users/company/', payload)


/**
 * @param {number[]} ids
 */
export const deleteUnits = ids => apiFetch('/company/units/', { ids }, 'DELETE')

export const promoteUnit = (id, payload) => apiFetch(`/company/units/promote/${id}`, payload)


async function apiFetch(endpoint, payload, method = 'GET') {
  const isFormDataPayload = payload instanceof FormData

  const options = payload ? {
    method: (method === 'GET') ? 'POST' : method,
    body: isFormDataPayload ? payload : JSON.stringify(payload)
  } : {}


  const headers = isFormDataPayload ? {} : {
    'Content-Type': 'application/json'
  }

  const token = getUserTokenLocalStorage()

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }


  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_WP_API_URL}/wp-json${endpoint}`, {
      method,
      ...options,
      headers
    })
      .then(async response => {
        if (response.ok) {
          resolve(response.json())
          return
        }

        if (response.status === 404) {
          reject(response)
          return
        }


        let body

        try {
          body = response.body ? await response.clone().json() : null
        } catch (e) {
          reject(response)
        }

        if ((response.status === 401) ||
          ((response.status === 400) && (body.data?.errorCode === 14))) {
          logoutUser()
          redirectToLogin()
          return
        }

        reject(response)
      })
  })

}
